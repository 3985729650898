
ul>li>ul>li{
    width: 100%;
}

.dropdown-menu {
    margin-top: 14px !important;
    width: 45vw !important;
    left: 0 !important; 
    background-color: grey !important;
    color: white !important;
  }

  .dropdown-menu .dropdown-item {
    cursor: pointer;
    color: rgb(113, 238, 113);
  }