.game_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh; 
}
  
  .game_grid {
    display: grid;
    grid-template-columns: repeat(4, 100px);
    grid-gap: 10px;
    justify-content: center;
  }
  
  .game_tile {
    width: 100px;
    height: 100px;
    perspective: 1000px; /* Needed for 3D effect */
  }
  
  .game_tile-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: transform 0.6s; /* Flip animation duration */
  }
  
  .game_tile.flip .game_tile-inner {
    transform: rotateY(180deg); /* Flip effect */
  }
  
  .game_tile-front, .game_tile-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden; /* Hide back side when flipped */
  }
  
  .game_tile-front {
    background-color: #e0e0e0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .game_tile-back {
    background-color: #add8e6;
    transform: rotateY(180deg);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .game_tile.matched .game_tile-inner {
    visibility: hidden;
  }
  
  .game_container img {
    max-width: 80px;
    max-height: 80px;
  }
  