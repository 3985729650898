*{
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
}
body{
  background-color: #505050;
}
.card {
  background-color: #fff !important;
}
a{
  text-decoration: none;
}
.w-90{
  width: 90% !important;
}
.card-title{
  color: black !important;
}